import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { InstitutionSignUpInformation, UserSignUpInformation } from 'app/interfaces/auth-interfaces';
import { AppState } from 'app/store/app.reducer';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';

import * as storeActions from '../store/actions';
import { PersistenceService } from './persistence.service';
import { CommonResponse } from 'app/interfaces/response-interfaces';
import { FirebaseFCMService } from './firebase.fcm.service';


@Injectable({ providedIn: 'root' })
export class AuthService {
    // private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _persistenceService = inject(PersistenceService);
    private _store = inject(Store<AppState>);
    private _messagingService = inject(FirebaseFCMService);

    baseUrl = environment.url;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    // set accessToken(token: string) {
    //     localStorage.setItem('accessToken', token);
    // }

    get accessToken(): string {
        let accessToken: string = '';
        this._store.select('auth').subscribe(auth=>{
            accessToken = auth.currentToken;
        });
        return accessToken;
    }


    get currentUser(): any {
        let currentUser=''
        this._store.select('auth').subscribe(auth=>{
          currentUser = auth.currentUser;
        });
        return currentUser;
    }

    get pharmaciesList(): any {
        let pharmaciesList=[]
        this._store.select('auth').subscribe(auth=>{
          pharmaciesList = auth.pharmaciesList;
        });
        return pharmaciesList;
    }

    get userId(): any {
        let userId=''
        this._store.select('auth').subscribe(auth=>{
          userId = auth.currentUser.id;
        });
        return userId;
    }

    get pharmacyChosenData(): any {
        let pharmacyChosenData=''
        this._store.select('auth').subscribe(auth=>{
          pharmacyChosenData = auth.currentPharmacy;
        });
        return pharmacyChosenData;
    }

    get authenticated(): boolean {
        let authenticated=false;
        this._store.select('auth').subscribe(auth=>{
          if(auth.currentToken !== null){
            authenticated = true;
          } else {
            authenticated = false;
          }
        });

        return authenticated;
    }

    get headers_http() {
        let header = {
        //   'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        }
        return {headers:header};
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; contrasena: string }): Observable<any> {
        // console.log('signing in');

        // Throw error, if the user is already logged in
        if (this.authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this.baseUrl}/farmacia/usuario/log-in`, credentials).pipe(
            // tap(response => console.log({response})),
            switchMap((response: CommonResponse) => {
                if(response.statusCode !== 200){
                    return throwError(response.message);
                    // return of(response.message)
                } else {
                    // Store the access token in the session storage
                    this._persistenceService.setToken(response.response.token);
                    this._persistenceService.setCurrentUser({email: response.response.email, name: response.response.nombre, id: response.response.idUsuario});

                    // // Set the authenticated flag to true
                    // this.authenticated = true;

                    this._store.dispatch(new storeActions.SetUserAction({
                        email: response.response.email,
                        name: response.response.nombre,
                        id: response.response.idUsuario
                    }));
                    this._store.dispatch(new storeActions.SetTokenAction(response.response.token));
                    this._store.dispatch(new storeActions.SetPharmaciesAction(response.response.farmacias));
                    this._messagingService.requestPermission().then((currentToken) => {
                        this._store.dispatch(new storeActions.SetFirebaseTokenAction(currentToken));
                    })
                    // Return a new observable with the response
                    return of(response);

                }

            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.response.token) {
                        this._persistenceService.setToken(response.response.token);
                        this._store.dispatch(new storeActions.SetTokenAction(response.response.token))
                        // this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    // this._authenticated = true;

                    // // Store the user on the user service
                    // this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        // localStorage.removeItem('accessToken');
        this._persistenceService.removeToken();

        // Remove the access token from Redux
        this._store.dispatch(new storeActions.UnsetAllAction())

        // // Set the authenticated flag to false
        // this.authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: UserSignUpInformation): Observable<CommonResponse> {
        return this._httpClient.post<CommonResponse>(`${this.baseUrl}/farmacia/usuario/registrar`, user);
    }

    /**
     * Institution sign up
     *
     * @param pharmacyData basic pharmacy information to sign it up
     */
    institutionSignUp(pharmacyData: InstitutionSignUpInformation): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}/farmacia/registrar`, pharmacyData, this.headers_http);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.authenticated == true) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
